import React, { useEffect, useState } from 'react'
// context
// import { useLayout } from '@context'
// antd
import ProLayout, { PageContainer } from '@ant-design/pro-layout'
// route
import { renderRoutes } from 'react-router-config'
import { useHistory, Link } from "react-router-dom";
import menu from './menu'
import RightContent from './comp/RightContent'
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
// import { useAuth0 } from "@auth0/auth0-react";
import { Spin } from 'antd';

const Comp = (props) => {
  const router = useHistory();
  const [second, setSecond] = useState(3);
  const { user, isAuthenticated, isLoading } = useAuth0();
  // const [theme] = useLayout()
  console.log(user)
  console.log(isAuthenticated)
  const { route: { routes } } = props
  console.log(props)
  useEffect(() => {
    let timer;
    if (!isAuthenticated) {
      timer = setInterval(() => {
        if (second === 0) {
          // console.log('redirect')
          clearInterval(timer);
          router.push('/auth/login')
        } else {
          console.log(second)
          setSecond(second => second - 1)
        }
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [isAuthenticated, second]);
  return (
    <>
      {isLoading && <Spin />}
      {isAuthenticated ?
        <ProLayout
          title="Member Admin"
          {...menu}
          // {...theme}
          rightContentRender={RightContent}
          onMenuHeaderClick={() => router.push('/')}
          breadcrumbRender={(routers = []) => [
            {
              path: '/',
              breadcrumbName: "首頁",
            },
            ...routers,
          ]}
          itemRender={(route, params, routes, paths) => {
            const first = routes.indexOf(route) === 0;
            return first ? (
              <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
            ) : (
                <span>{route.breadcrumbName}</span>
              );
          }}
        >
          {renderRoutes(routes)}
        </ProLayout>
        :
        <div>你沒有登入，{second} 秒後跳回登陸頁。</div>
      }
    </>
  )
}

export default withAuthenticationRequired(Comp, {
  onRedirecting: () => <Spin />,
});
