import logs from './logs';
import CryptoJS from 'crypto-js';
import * as config from '@config/const';

export const isClient = typeof window !== undefined;

export const dev = process.env.NODE_ENV !== 'production';


/**
 * Set token data to local storage
 * @param  {Obj} data
 */
export function setToken(data) {
	try {
		if (isClient) {
			window.localStorage.setItem(
				config.store.token,
				encrypt(data, config.secret.token
				));
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('setToken', error);
	}
}

/**
 * Get token data from local storage
 * @return {Obj}
 */
export function getToken() {
	try {
		if (isClient) {
			return decrypt(
				window.localStorage.getItem(config.store.token),
				config.secret.token
			)
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('getToken', error);
	}
}


/**
 * Remove user data to local storage
 * @param  {Obj} data
 */
export function removeUserData() {
	try {
		if (isClient) {
			window.localStorage.removeItem(config.store.user);
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('removeUserData', `error`);
	}
}

/**
 * Set user data to local storage
 * @param  {Obj} data
 */
export function setUserData(data) {
	try {
		if (isClient) {
			window.localStorage.setItem(
				config.store.user,
				encrypt(data, config.secret.user
				));
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('setUserData', error);
	}
}

/**
 * Get user data from local storage
 * @return {Obj}
 */
export function getUserData() {
	try {
		if (isClient) {
			return decrypt(
				window.localStorage.getItem(config.store.user),
				config.secret.user
			)
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('getUserData', error);
	}
}

/**
 * Remove user data to local storage
 * @param  {Obj} data
 */
export function removeAuth() {
	try {
		if (isClient) {
			let keysToRemove = [config.store.user, config.store.token];
			keysToRemove.forEach(key => window.localStorage.removeItem(key));
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('removeAuth', `error`);
	}
}

/**
 * @param  {String} data
 * @param  {String} secret
 * 
 * @return {String} 
 * encrypt data
 */

export function encrypt(data, secret) {
	const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secret);
	return ciphertext.toString();
}
/**
 * @param  {String} data
 * @param  {String} secret
 * 
 * @return {Obj}
 * decrypt data
 */

export function decrypt(data, secret) {
	var bytes = CryptoJS.AES.decrypt(data, secret);
	return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}