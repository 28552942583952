// menu.js
import {
  DashboardOutlined,
  DatabaseOutlined,
  SmileOutlined, SettingOutlined, PlaySquareOutlined
} from '@ant-design/icons'

// route
import { Link } from 'react-router-dom'

const menuItem = (item, dom) => <Link to={item.path} >{dom}</Link>

const menu = {
  path: '/',
  routes: [
    {
      path: '/',
      name: 'Dashboard',
      icon: <DashboardOutlined />
    },
    // {
    //   path: '/test',
    //   name: 'Crud demo',
    //   icon: <DatabaseOutlined />
    // },
    {
      path: "/admin/leads",
      name: "名單列表",
      icon: <PlaySquareOutlined />,
      routes: [
        {
          path: "/admin/leads",
          name: "歷史名單清單",
          icon: <SettingOutlined />,
        },
        {
          path: "/admin/leads/create",
          name: "新增名單",
          icon: <SettingOutlined />,
        },
        {
          path: "/admin/leads/edit/:id",
          name: "編輯名單",
          icon: <SettingOutlined />,
          hideInMenu: true,
        },
        // {
        //   path: "/admin/leads/upload",
        //   name: "上傳Excel檔",
        //   icon: <SettingOutlined />,
        // },
      ],
    },
  ]
}

const menuConfig = {
  route: menu,
  menuItemRender: menuItem
}

export default menuConfig
