import { useEffect, useState } from "react";
import _ from "lodash";
import { useHistory, Link, useParams } from "react-router-dom";
import ProForm, { ProFormText, ProFormRadio } from "@ant-design/pro-form";
import { PageHeaderWrapper, PageContainer } from "@ant-design/pro-layout";
import { Alert, Card, Spin } from "antd";
import Form from './components/form'

const Edit = () => {
  const router = useHistory();
  const { id } = useParams();
  const [lead, setLead] = useState({});
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const url = `${API_ENDPOINT}/api/leads/${id}`

  useEffect(() => {
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((leads) => {
        setLead({});
        // Do not forgot the json data
        setLead(leads.data);
      });
  }, [id]);

  const handleEdit = (values) => {
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(values),
    })
      .then(response => response.json())
      .then((res) => {
        if (res.success) {
          setTimeout(() => {
            router.push(`/admin/leads/${values.id}`);
          }, 1000);
        } else {
          alert(res.data.error.sqlMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <PageContainer>
        {_.isEmpty(lead) && <Spin />}
        {!_.isEmpty(lead) && <Form type="EDIT" entity={lead} onSubmit={handleEdit} />}
      </PageContainer>
    </>
  );
};
export default Edit;
