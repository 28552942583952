// styles
import less from './styles.module.less'

function Body({ children, title }) {
    return (
        <div style={{ marginBottom: 24 }}>
            <h3 className={less.title}>{title}</h3>
            {children}
        </div>
    )
}

export default Body