// dashboard
import React, { Component, Suspense, useEffect } from 'react';
import { PageHeaderWrapper, GridContent } from '@ant-design/pro-layout';
import PageLoading from '@components/PageLoading';
import { connect, useSelector, useDispatch } from 'react-redux';

const IntroduceRow = React.lazy(() => import('@components/IntroduceRow'));


const Analysis = ({ dispatch, dashboardAndanalysis = {}, loading, count }) => {
  // const {
  //   visitData: []
  // } = dashboardAndanalysis;
  console.log('dashboardAndanalysis')
  console.log(dashboardAndanalysis)
  console.log(count)
  // TODO: side effect->action ->reducer
  useEffect(() => {
    dispatch({
      type: 'fetchDashboardAndanalysis',
    });
    return () => {
      // cleanup
    };
  });

  return (
    <>
      <PageHeaderWrapper>
        <GridContent>
          <Suspense fallback={<PageLoading />}>
            {/* <IntroduceRow loading={loading} visitData={visitData} /> */}
          </Suspense>
        </GridContent>
      </PageHeaderWrapper>
    </>
  )
}

export default connect(({ dashboardAndanalysis, loading, count }) => ({
  dashboardAndanalysis,
  count,
  // loading: loading.effects['dashboardAndanalysis/fetch'],
}))(Analysis);
