import React,{ createContext, useContext, useReducer } from 'react';
import { initialState, reducer } from './reducer';

export const LayoutContext = createContext();

export const useLayout = () => useContext(LayoutContext);

export const LayoutProvider = ({children}) => (
    <LayoutContext.Provider value={useReducer(reducer, initialState)}>
        {children}
    </LayoutContext.Provider>
);
