import React, { useRef, FunctionComponent } from "react";
import { useHistory, Link } from "react-router-dom";
import ProTable, { TableDropdown } from "@ant-design/pro-table";
import type { ProColumns, ActionType } from "@ant-design/pro-table";
import { PageHeaderWrapper, PageContainer } from "@ant-design/pro-layout";

import {
  Button,
  Tag,
  Space,
  Menu,
  Dropdown,
  Modal,
  Upload,
  message,
} from "antd";
import {
  PlusOutlined,
  EllipsisOutlined,
  UploadOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const pageSize = 10;

type GithubIssueItem = {
  url: string;
  id: number;
  number: number;
  title: string;
  labels: {
    name: string;
    color: string;
  }[];
  state: string;
  comments: number;
  created_at: string;
  updated_at: string;
  closed_at?: string;
};
const { confirm } = Modal;

const request = async (params: any, sort: any, filter: any) => {
  // console.log(sort, filter);
  console.log(params, sort, filter);
  const { current, pageSize, ...queries } = params;
  const API = new URL(`${API_ENDPOINT}/api/leads`);
  Object.keys(queries).forEach((q) => API.searchParams.set(q, queries[q]));
  // console.log(API.href);
  const res = await fetch(API.href);
  const { data, success, length: total } = await res.json();
  return {
    total,
    data,
    success,
  };
};

type MemberAdmResponse = {
  success: boolean;
  data: Object;
};

const Index = () => {
  // https://github.com/ant-design/pro-table#actiontype
  const actionRef: any = useRef<ActionType>();
  const showPromiseConfirm = (id: number) => (e: any) => {
    confirm({
      title: "Do you want to delete these items?",
      icon: <ExclamationCircleOutlined />,
      content:
        "When clicked the OK button, this dialog will be closed after deleted",
      async onOk() {
        try {
          const res: Response = await fetch(`${API_ENDPOINT}/api/leads/${id}`, {
            method: "DELETE",
          });
          const data: MemberAdmResponse = await res.json();
          if (data.success) {
            actionRef.current.reload();
          } else {
            console.log(data);
          }
        } catch (e) {
          console.log(e);
        }
      },
      onCancel() {},
    });
  };

  const props = {
    name: "file",
    action: `${API_ENDPOINT}/api/leads/upload`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  const columns: ProColumns<GithubIssueItem>[] = [
    {
      title: "序號",
      dataIndex: "index",
      valueType: "indexBorder",
      width: 48,
    },
    {
      title: "名稱",
      dataIndex: "name",
      copyable: true,
      ellipsis: true,
      tip: "标题过长会自动收缩",
      formItemProps: {
        rules: [
          {
            required: true,
            message: "此项为必填项",
          },
        ],
      },
      render: (text, record, _, action) => {
        return <Link to={`/admin/leads/${record.id}`}>{text}</Link>;
      },
    },
    {
      title: "email",
      dataIndex: "email",
      copyable: true,
      ellipsis: true,
      tip: "标题过长会自动收缩",
      formItemProps: {
        rules: [
          {
            required: true,
            message: "此项为必填项",
          },
        ],
      },
    },
    {
      title: "電話",
      dataIndex: "phone",
      copyable: true,
      ellipsis: true,
      tip: "标题过长会自动收缩",
      // formItemProps: {
      //   rules: [
      //     {
      //       required: true,
      //       message: "此项为必填项",
      //     },
      //   ],
      // },
    },
    {
      title: "狀態",
      dataIndex: "is_deleted",
      filters: true,
      onFilter: true,
      valueType: "select",
      valueEnum: {
        0: { text: "有效名單", status: "Success" },
        1: { text: "無效名單", status: "Default" },
      },
    },
    // {
    //   title: "标签",
    //   dataIndex: "labels",
    //   search: false,
    //   renderFormItem: (_, { defaultRender }) => {
    //     return defaultRender(_);
    //   },
    //   render: (_, record) => (
    //     <Space>
    //       {record.labels.map(({ name, color }) => (
    //         <Tag color={color} key={name}>
    //           {name}
    //         </Tag>
    //       ))}
    //     </Space>
    //   ),
    // },
    {
      title: "建立时间",
      key: "showTime",
      dataIndex: "build_ts",
      valueType: "dateTime",
      sorter: true,
      hideInSearch: true,
    },
    {
      title: "上次修改时间",
      key: "showTime",
      dataIndex: "alter_ts",
      valueType: "fromNow",
      sorter: true,
      hideInSearch: true,
    },
    // {
    //   title: "修改时间",
    //   dataIndex: "alter_ts",
    //   valueType: "dateRange",
    //   hideInTable: true,
    //   search: {
    //     transform: (value) => {
    //       return {
    //         startTime: value[0],
    //         endTime: value[1],
    //       };
    //     },
    //   },
    // },
    {
      title: "操作",
      valueType: "option",
      render: (text, record, _, action) => [
        <Link to={`/admin/leads/${record.id}/edit`}>编辑</Link>,
        // <Link to={`/admin/leads/${record.id}`}>查看</Link>,
        <Button onClick={showPromiseConfirm(record.id)}>刪除</Button>,
        // <TableDropdown
        //   key="actionGroup"
        //   onSelect={() => action?.reload()}
        //   menus={[
        //     { key: "copy", name: "复制" },
        //     { key: "delete", name: "删除" },
        //   ]}
        // />,
      ],
    },
  ];
  // const { API_ENDPOINT =  "https://member-admin.vercel.app" } = process.env;
  // const { data, isPending, error } = useFetch("http://localhost:3000/api/leads");
  return (
    <PageContainer>
      {/* <PageContainer> */}
      {/*
        {isPending && <h3>Loading</h3>}
        {error && <div>{error}</div>}
        {data && <MemDetailList leads={data} />} */}
      <ProTable
        columns={columns}
        actionRef={actionRef}
        request={request}
        // editable={{
        //   type: "multiple",
        // }}
        rowKey="id"
        search={{
          labelWidth: "auto",
        }}
        // form={{
        //   // 由于配置了 transform，提交的参与与定义的不同这里需要转化一下
        //   syncToUrl: (values, type) => {
        //     if (type === "get") {
        //       return {
        //         ...values,
        //         created_at: [values.startTime, values.endTime],
        //       };
        //     }
        //     return values;
        //   },
        // }}
        pagination={{
          pageSize,
        }}
        dateFormatter="string"
        // headerTitle="高级表格"
        toolBarRender={() => [
          <Link to="/admin/leads/create">
            <Button key="button" icon={<PlusOutlined />} type="primary">
              新建
            </Button>
          </Link>,
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>上傳名單</Button>
          </Upload>,
          // <Dropdown key="menu" overlay={menu}>
          //   <Button>
          //     <EllipsisOutlined />
          //   </Button>
          // </Dropdown>,
        ]}
      />
      {/* </PageContainer> */}
    </PageContainer>
  );
};

export default Index;
