/**
 * ? dev 
 * @var {bool}
 */
export const dev = process.env.NODE_ENV !== 'production'

/**
 * @param  {string} name
 * @param  {string} msg
 * 
 * @return {string}
 */
export function warn(name, msg) {
    if(dev) console.warn(name, msg)
}

/**
 * @param  {string} name
 * @param  {string} msg
 * 
 * @return {string}
 */
export function log(name, msg) {
    if(dev) console.log(name, msg)
}

const logs = {
    log: log,
    warn: warn
}

export default logs