import { useEffect, useState } from "react";
import { PageContainer } from '@ant-design/pro-layout'
import { useHistory, Link, useParams } from "react-router-dom";
import { Alert, Card, Spin } from "antd";
import Form from './components/form'
import _ from "lodash";

function TestListPage() {
  const { id } = useParams();
  const [lead, setLead] = useState({});
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const url = `${API_ENDPOINT}/api/leads/${id}`

  useEffect(() => {
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((leads) => {
        // Do not forgot the json data
        setLead(leads.data);
      });
  }, [id]);

  return (
    <PageContainer
      header={{
        title: '名單詳細',
      }}
    >
      {_.isEmpty(lead) && <Spin />}
      {!_.isEmpty(lead) && <Form type="DETAIL" entity={lead} />}
    </PageContainer>
  )
}

export default TestListPage;
