
import ProForm, { ProFormText } from '@ant-design/pro-form'

function FormComp() {
    return (
        <ProForm.Group>
            <ProFormText name="name" width="md" label="Name" />
            <ProFormText name="email" width="md" label="Email" />
            <ProFormText name="phone" width="md" label="Phone" />
        </ProForm.Group>
    )
}

export default FormComp