import { useState } from "react";
import { useHistory } from "react-router-dom";
import ProForm, { ProFormText, ProFormRadio, ProFormTextArea } from "@ant-design/pro-form";
import { PageHeaderWrapper, PageContainer } from "@ant-design/pro-layout";
import moment from "moment";
import { Alert, Card } from "antd";
import Form from './components/form'

const Create = () => {
  const router = useHistory();
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const url = `${API_ENDPOINT}/api/leads`

  const handleSubmit = (values) => {
    fetch(url, {
      method: "POST",
      headers: {
        // 溝通方式、媒介、時間等，aka spec
        "content-type": "application/json; charset=utf-8",
      },
      //有關於傳遞資料的內容（根據content-type）
      body: JSON.stringify(values),
    })
      .then(response => response.json())
      .then((res) => {
        if (res.success) {
          alert("We have added a new lead.");
          setTimeout(() => {
            router.push("/admin/leads");
          }, 1000);
        } else {
          alert(res.data.error.sqlMessage);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };


  return (
    <>
      <PageContainer>
        <Form type="CREATE" onSubmit={handleSubmit} />
      </PageContainer>
    </>
  );
};

export default Create;
