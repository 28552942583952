import React from 'react'
// styles
import less from './styles.module.less';
// antd
import { Avatar, Dropdown, Menu } from 'antd'
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { useAuth0 } from "@auth0/auth0-react";

function AvatarDropdown(props) {
  const { logout } = useAuth0();
  const { user, isAuthenticated, isLoading } = useAuth0();

  const { menu, label, src } = props;
  const menuDropdown = (
    <Menu className={less.menu} selectedKeys={[]}>
      {menu.map(x => <Menu.Item key={x.to}>{x.icon} {x.label}</Menu.Item>)}
      {menu && <Menu.Divider />}
      <Menu.Item key="logout" onClick={() => logout({ returnTo: window.location.origin })}>
        <LogoutOutlined /> logout
            </Menu.Item>
    </Menu>
  );
  if (isLoading) {
    return <div>Loading ...</div>;
  }
  console.log(user)
  console.log(isLoading)
  return (
    <>
      {isAuthenticated && user && (
        <Dropdown overlay={menuDropdown}>
          <span className={`${less.action} ${less.account}`}>
            <Avatar size="small" className={less.avatar} src={user.picture} alt="avatar" />
            <span className={`${less.name} anticon`}>{user.name}</span>
          </span>
        </Dropdown>
      )
      }

    </>
  )
}

AvatarDropdown.defaultProps = {
  label: 'Username',
  src: 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
  menu: [
    {
      to: '/profile',
      label: 'Profile',
      icon: <SettingOutlined />
    },
  ]
}

export default AvatarDropdown
