import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import ProForm, {
  ProFormText,
  ProFormRadio,
  ProFormTextArea,
  ProFormSelect,
  ProFormDatePicker,
  ProFormDateTimePicker
} from "@ant-design/pro-form";
import moment from "moment";
import { Alert, Card } from "antd";

const CREATE = 'CREATE'
const EDIT = 'EDIT'
const DETAIL = 'DETAIL'

const FormComp = ({ type, onSubmit, entity }) => {
  console.log(entity)
  const router = useHistory();
  const [created, setCreated] = useState(false);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  // const url = `${API_ENDPOINT}/api/leads/${id}`

  const requestBy = ({ url, cb }) => async ({ keyWords }) => {
    try {
      const res = await fetch(url)
      const { success, data } = await res.json(res)
      const options = data.map(val => cb(val))
      console.log(data)
      return options
    } catch (e) {
      return [{
        label: keyWords,
        value: e,
      }]
    }
  }
  const genSelectFormate = (val) => ({ label: val.name, value: val.id });
  const requestSource = requestBy({ url: `${API_ENDPOINT}/api/sources`, cb: genSelectFormate })
  const requestOrganizations = requestBy({ url: `${API_ENDPOINT}/api/organizations`, cb: genSelectFormate })
  const requestTags = requestBy({ url: `${API_ENDPOINT}/api/tags`, cb: genSelectFormate })
  console.log(entity?.tags.map(tag => genSelectFormate(tag)))
  // const requestOrganizations
  // const requestTags
  return (
    <>
      {(type === CREATE || entity) &&
        <Card
          title={type === DETAIL ? ' 顯示名單' : type === EDIT ? '編輯表單' : '建立表單'}
          extra={[
            type === DETAIL && <Link to={`/admin/leads/${entity.id}/edit`}>编辑</Link>,
            type === EDIT && <Link to={`/admin/leads/${entity.id}`}>檢視</Link>
          ]}
        >
          <ProForm
            onFinish={async (values) => {
              values.build_ts = moment().subtract(8, "hours").format("YYYY-MM-DD HH:mm");
              // console.log(values);
              onSubmit(values);
            }}
            submitter={
              type !== DETAIL
            }
            initialValues={{
              source_id: entity?.source_id,
              tags: entity?.tags.map(tag => tag.id),
              organizations: entity?.organizations.map(o => o.id),
            }}
          >
            {type !== CREATE &&
              <ProFormText
                width="md"
                name="id"
                label="id"
                placeholder="id"
                fieldProps={{ disabled: true }}
                initialValue={type === CREATE ? '' : entity?.id}
              />
            }
            <ProFormRadio.Group
              disabled={type === DETAIL}
              name="is_deleted"
              label="是否為有效名單？"
              options={[
                {
                  label: 'yes',
                  value: 0,
                },
                {
                  label: 'no',
                  value: 1,
                },
              ]}
              initialValue={type === CREATE ? 0 : entity?.is_deleted}
            />
            <ProFormText
              width="md"
              name="email"
              label="Email"
              placeholder="email"
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? 'demo@gmail.com' : entity?.email}
              rules={[{ required: true, message: '請輸入email' }]}
            />
            <ProFormText
              disabled={type === DETAIL}
              width="md"
              name="name"
              label="名稱"
              placeholder={type !== DETAIL && 'name'}
              // fieldProps={{ disabled: type === DETAIL, defaultValue: entity?.name }}
              initialValue={type === CREATE ? '假名' : entity?.name}
            />
            <ProFormText
              width="md"
              name="phone"
              label="電話"
              placeholder={type !== DETAIL && 'phone'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '09xxxxxxxx' : entity?.phone}

            />
            <ProFormText
              width="md"
              name="last_name"
              label="姓氏"
              placeholder={type !== DETAIL && 'last_name'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '' : entity?.last_name}
            />
            <ProFormText
              width="md"
              name="first_name"
              label="姓名"
              placeholder={type !== DETAIL && 'first_name'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '' : entity?.first_name}
            />
            <ProFormText
              width="md"
              name="fb_link"
              label="臉書"
              placeholder={type !== DETAIL && 'fb_link'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '' : entity?.fb_link}
            />
            <ProFormText
              width="md"
              name="ig_link"
              label="IG"
              placeholder={type !== DETAIL && 'ig_link'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '' : entity?.ig_link}
            />
            <ProFormRadio.Group
              disabled={type === DETAIL}
              name="gender"
              label="性別"
              options={[
                {
                  label: '男生',
                  value: 'male',
                },
                {
                  label: '女生',
                  value: 'female',
                },
              ]}
              initialValue={type === CREATE ? 'male' : entity?.gender}
              rules={[{ required: true, message: '請選擇性別' }]}

            />
            {/* <ProFormText
              width="md"
              name="birth"
              label="出生年月"
              placeholder={type !== DETAIL && 'birth'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '1997/02/20' : entity?.birth}
            /> */}
            <ProFormDatePicker
              width="md"
              name="birth"
              label="出生年月"
              placeholder={type !== DETAIL && 'birth'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '1997/02/20' : entity?.birth}
            />
            <ProFormSelect
              disabled={type === DETAIL}
              width="md"
              name="source_id"
              label="來源(支援搜尋)"
              showSearch
              request={requestSource}
              placeholder="請選擇名單來源"
              rules={[{ required: true, message: '請選擇名單來源' }]}
            />
            <ProFormSelect
              disabled={type === DETAIL}

              width="md"
              name="organizations"
              label="單位(支援多選)"
              request={requestOrganizations}
              // valueEnum={{
              //   happycoding: '快樂學程式',
              //   codeformarketing: '行銷工程台灣',
              // }}
              fieldProps={{
                mode: 'multiple',
              }}
              placeholder="請輸入該名單屬於哪個單位"
              rules={[
                { required: true, message: '請輸入該名單屬於哪個單位!', type: 'array' },
              ]}
            />
            <ProFormSelect
              disabled={type === DETAIL}

              width="md"
              name="tags"
              label="標籤(支援多選)"
              request={requestTags}
              // valueEnum={{
              //   red: 'Red',
              //   green: 'Green',
              //   blue: 'Blue',
              // }}
              fieldProps={{
                mode: 'multiple',
              }}
              placeholder="請輸入標籤"
              rules={[
                { required: true, message: '請輸入標籤!', type: 'array' },
              ]}
            />
            <ProFormTextArea
              width="md"
              name="note"
              label="備註"
              placeholder={type !== DETAIL && 'note'}
              fieldProps={{ disabled: type === DETAIL }}
              initialValue={type === CREATE ? '' : entity?.note}
            />
            {type !== CREATE && (
              <>
                <ProFormDateTimePicker
                  width="md"
                  name="build_ts"
                  label="建立時間"
                  placeholder={type !== DETAIL && 'build_ts'}
                  fieldProps={{ disabled: type === DETAIL || type === EDIT }}
                  initialValue={type === CREATE ? '' : entity?.build_ts}
                />
                <ProFormDateTimePicker
                  width="md"
                  name="alter_ts"
                  label="最新修改時間"
                  placeholder={type !== DETAIL && 'alter_ts'}
                  fieldProps={{ disabled: type === DETAIL || type === EDIT }}
                  initialValue={type === CREATE ? '' : entity?.alter_ts}
                />

                {/* <ProFormText
                  width="md"
                  name="is_deleted"
                  label="是否為有效名單？"
                  placeholder={type !== DETAIL && 'is_deleted'}
                  fieldProps={{ disabled: type === DETAIL }}
                  initialValue={type === CREATE ? '' : entity?.is_deleted}
                /> */}
              </>
            )}

          </ProForm>
        </Card>
      }
    </>
  );
};

export default FormComp;
