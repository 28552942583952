import React from 'react'
// route
import { renderRoutes } from 'react-router-config'
// context
import { AppProvider, LayoutProvider } from '@context'
// antd
import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhTW from 'antd/lib/locale/zh_TW'
import zhCN from 'antd/lib/locale/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

export default function Comp(props) {
  const { route: { routes } } = props
  moment.locale('zh-cn');
  return (
    <AppProvider>
      <ConfigProvider locale={zhTW}>
        <LayoutProvider>
          {renderRoutes(routes)}
        </LayoutProvider>
      </ConfigProvider>
    </AppProvider>
  )
}
