import React from 'react';
// pro layout
// import { PageHeaderWrapper } from '@ant-design/pro-layout';
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

export default LoginButton;
// export default function Comp() {
//     return (
//         <PageHeaderWrapper>
//             Dashboard
//         </PageHeaderWrapper>
//     )
// }
