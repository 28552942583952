import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
// import MainLayout from "@/components/layout/main";
import { PageContainer } from "@ant-design/pro-layout";
const Uploads = () => {
  const { Dragger } = Upload;
  // http://localhost:3000/api/leads
  const props = {
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileLists);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <>
      {/* <MainLayout> */}
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p>
      </Dragger>
      {/* </MainLayout> */}
    </>
  );
};
export default Uploads;
