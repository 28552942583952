import TestListPage from './list';
import TestAddPage from './add';
import TestEditPage from './edit';
import TestDetailPage from './detail';

export const routes = [
    {
        exact: true,
        path: '/test',
        component: TestListPage
    },
    {
        path: '/test/add/',
        component: TestAddPage
    },
    {
        path: '/test/:id',
        component: TestEditPage
    },
    {
        path: '/test/detail/:id',
        component: TestDetailPage
    },
]