// test/list.js
import { PageContainer } from '@ant-design/pro-layout'

function TestListPage() {
    return (
        <PageContainer
            header={{
                title: 'Test Details',
            }}
        >
            sdfsdfdsfdf
        </PageContainer>
    )
}


export default TestListPage;