import { site, theme } from './const';

const config = {
    logo: site.logo,
    title: site.title,

    // theme
    navTheme: 'light',
    colorWeak: false,
    contentWidth: 'Fluid',
    primaryColor: theme.primaryColor,

    // header
    fixedHeader: false,
    autoHideHeader: false,
    headerTheme: 'dart',

    // sidebar
    layout: 'sidemenu',
    fixSiderbar: true,
    
    // other
    pwa: false,
    iconfontUrl: ""
}

export default config