import { actionTypes } from './actions'
// import { HYDRATE } from 'next-redux-wrapper'

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

const initialState = {
  dashboardAndanalysis: {
    visitData: [],
  },
  loading: false,
  count: 0,
  error: false,
  lastUpdate: 0,
  light: false,
  placeholderData: null,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case "HYDRATE": {
      return { ...state, ...action.payload }
    }

    case "save": {
      return { ...state, ...action.payload, count: 2 };
    }

    case actionTypes.FAILURE:
      return {
        ...state,
        ...{ error: action.error },
      }

    case actionTypes.INCREMENT:
      return {
        ...state,
        ...{ count: state.count + 1 },
      }

    case actionTypes.DECREMENT:
      return {
        ...state,
        ...{ count: state.count - 1 },
      }

    case actionTypes.RESET:
      return {
        ...state,
        ...{ count: initialState.count },
      }

    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        ...{ placeholderData: action.data },
      }

    case actionTypes.TICK_CLOCK:
      return {
        ...state,
        ...{ lastUpdate: action.ts, light: !!action.light },
      }

    default:
      return state
  }
}


function counter(state = 0, action) {
  switch (action.type) {
    case 'INCREMENT':
      return state + 1
    case 'DECREMENT':
      return state - 1
    default:
      return state
  }
}
// export default reducer

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  reducer,
  counter
  // ... // rest of your reducers
})

export default createRootReducer
