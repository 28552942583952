import config from '@config/layout';
import { getThemeSetting, setThemeSetting } from '@utils';

export const initialState = getThemeSetting() || config;

export const reducer = (state, action) => {
    switch (action.type) {
        case 'CHANGE_LAYOUT':
            const newState = {...state, ...action.payload};
            setThemeSetting(newState);
            return newState;
            
        case 'RESET_LAYOUT':
            setThemeSetting(config);
            return config;
            
        default:
            return state;
    }
};