// router
import { history } from '@router';
// utils
import { removeUserData } from '@utils/auth';

export const initialState = {
    usr: {},
    userInfo: {},
    auth: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case 'SET_USER':
            let { User, ...UserInfo } = action.payload;
            return {
                auth: true,
                user: User,
                userInfo: UserInfo,
            };

        case 'LOGOUT':
            removeUserData();
            history.push('/login');
            return {
                usr: {},
                userInfo: {},
                auth: false
            };

        default:
            return state;
    }
};