import React from 'react'
// child
import Avatar from './Avatar'
import less from './styles.module.less'
// components
import { ThemeSetting } from '@components/theme'

const theme = 'dart'
const layout = 'top'

function Action(props) {
  return <div {...props} className={less.action} />
}

function Wapper(props) {
  let className = less.right
  if (theme === 'dark' && layout === 'top') className = `${less.right}  ${less.dark}`
  return React.createElement('div', { className: className, ...props })
}

function RightContant() {
  return (
    <Wapper>
      <Avatar />
      {/* <ThemeSetting Wapper={Action}/> */}
    </Wapper>
  )
}

export default RightContant
