import axios from 'axios';
// config
import { url } from '@config/api';
// utils
import logs from '@utils/logs';
// import { getToken } from '@utils/auth';
  
const successHandler = (response) => {
    return response
}

const errorHandler = (error) => {
    if (!error.response) {
        logs.warn('error', error)
    }

    return Promise.reject({ ...error })
}


export default function api() {
    // let headers = {};
    // let token = getToken();
    
    // if(token) headers['X-JWTtoken'] = token.flim
    const axiosInstance = axios.create({
        baseURL: url,
        // headers: headers
    });

    // axiosInstance.interceptors.request.use(
    //     request => requestHandler(request)
    // );

    axiosInstance.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    );

    return axiosInstance;
}