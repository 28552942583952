// svg
import darkSvg from '@assets/svg/dark.svg';
import lightSvg from '@assets/svg/light.svg';
import topMenuSvg from '@assets/svg/top-menu.svg';
import sideMenuSvg from '@assets/svg/side-menu.svg';
// context
import { useLayout } from '@context/layout';
// antd
import { Divider, List, Switch } from 'antd';
// comp
import { Body, BlockCheckbox } from './comp';

function ThemeSettingPage() {
    const [config, dispatch] = useLayout();

    const _changeSetting = n => v => {
        var setting = {
            [n]: v
        }
        if (n === 'contentWidth') {
            setting.contentWidth = !v ? 'Fluid' : 'Fixed';
        }
        dispatch({ type: 'CHANGE_LAYOUT', payload: setting })
    }

    return (
        <div className="ant-pro-setting-drawer-content" >
            <Body title='Theme'>
                <BlockCheckbox
                    list={theme.page}
                    value={config.navTheme}
                    onChange={_changeSetting('navTheme')}
                />
                <Divider />
            </Body>
            <Body title='Navigation'>
                <BlockCheckbox
                    value={config.layout}
                    list={theme.navigation}
                    onChange={_changeSetting('layout')}
                />
                <Divider />
            </Body>
            <Body title='Layouts'>
                <List
                    split={false}
                    renderItem={renderListItems}
                    dataSource={[
                        {
                            title: 'Fixed Content Width',
                            action: (
                                <Switch
                                    size="small"
                                    checked={config.contentWidth === 'Fixed'}
                                    onChange={_changeSetting('contentWidth')}
                                />
                            )
                        },
                        {
                            title: 'Fixed Header',
                            action: (
                                <Switch
                                    size="small"
                                    checked={config.fixedHeader}
                                    onChange={_changeSetting('fixedHeader')}
                                />
                            )
                        },
                        {
                            title: 'Fixed Siderbar',
                            action: (
                                <Switch
                                    size="small"
                                    checked={config.fixSiderbar}
                                    onChange={_changeSetting('fixSiderbar')}
                                />
                            )
                        },
                    ]}
                />
            </Body>
        </div>
    )
}

const renderListItems = (item) => {
    return (
        <List.Item actions={[item.action]}>
            <span style={{ opacity: item.disabled ? 0.5 : 1 }}>{item.title}</span>
        </List.Item>
    );
}

const theme = {
    page: [
        {
            key: 'light',
            title: 'Light',
            url: lightSvg,
        },
        {
            key: 'dark',
            title: 'Dark',
            url: darkSvg,
        }
    ],
    navigation: [
        {
            key: 'sidemenu',
            title: 'Side menu',
            url: sideMenuSvg,
        },
        {
            key: 'topmenu',
            title: 'Top menu',
            url: topMenuSvg,
        },
    ]
}

export default ThemeSettingPage