import api from '@utils/api'
import { Button } from 'antd'
import ProTable from '@ant-design/pro-table'

const columns = [
    {
        key: 'Id',
        title: 'Id',
        dataIndex: 'id',
    },
    {
        key: 'Name',
        title: 'Name',
        copyable: true,
        dataIndex: 'name',
    },
    {
        key: 'Email',
        title: 'Email',
        dataIndex: 'email',
    },
    {
        key: 'Phone',
        title: 'Phone',
        dataIndex: 'phone',
    },
    {
        key: 'Website',
        title: 'Website',
        dataIndex: 'website',
    },
]

function getParams(params, sorter, filter) {
    var str = '?'
    if (params) {
        const { current, pageSize } = params
        if (current) str += `_page=${current}`
        if (pageSize) str += `&_limit=${pageSize}`
    }
    return str
}

const requestData = params => {
    var result = {}
    const endpointParams = getParams(params)
    const response = api().get('https://jsonplaceholder.typicode.com/users' + endpointParams)
        .then(response => {
            result.total = response.headers['x-total-count']
            return response.data
        }).catch(err => {
            console.warn(err)
            return {
                data: [],
                success: false,
            }
        })
        .then(data => {
            return {
                ...result,
                data: data,
                success: true,
            }
        })

    return response
}

function Table() {
    return (
        <ProTable
            columns={columns}
            request={requestData}
            rowKey={record => record.id}
            pagination={{
                pageSize: 5,
                showQuickJumper: true,
            }}
            toolBarRender={() => [
                <Button key="button" type="primary">
                    Add New
                    </Button>,
            ]}
        />
    )
}

export default Table