// layout
import RootLayout from '@layouts/root';
import AuthLayout from '@layouts/auth';
import DashboardLayout from '@layouts/dashboard';
// auth
import LoginPage from '@pages/auth/login';
import RegisterPage from '@pages/auth/register';
// dashboard
import DashboardPage from '@pages/dashboard/home';
import NotFoundPage from '@pages/dashboard/notfound';
import ThemePage from '@pages/dashboard/theme';
import ProfilePage from '@pages/dashboard/profile';

import * as test from '@pages/dashboard/test';
import * as leads from '@pages/dashboard/leads';

const authRoutes = [
  {
    path: "/",
    exact: true,
    component: LoginPage
  },
  {
    path: "/auth/login",
    component: LoginPage
  },
  {
    path: "/auth/register",
    component: RegisterPage
  },
]

const dashboardRoutes = [
  {
    path: "/",
    exact: true,
    component: DashboardPage
  },
  {
    path: "/theme",
    component: ThemePage
  },
  {
    path: "/profile",
    component: ProfilePage
  },
  ...test.routes,
  ...leads.routes,
  {
    component: NotFoundPage
  },
]

export const router = [
  {
    component: RootLayout,
    routes: [
      {
        path: "/auth",
        routes: authRoutes,
        component: AuthLayout,
      },
      {
        path: "/",
        routes: dashboardRoutes,
        component: DashboardLayout,
      },
    ]
  }
];
