// test/list.js
import { Button } from 'antd'
import { DrawerForm } from '@ant-design/pro-form'
import { PageContainer } from '@ant-design/pro-layout'
// self
import Form from './form'
import Table from './table'


const header = {
    title: 'Test List',
    breadcrumb: {
        routes: [
            {
                path: '/',
                breadcrumbName: 'Home',
            },
            {
                path: '/test',
                breadcrumbName: 'Test',
            },
        ],
    },
    // extra: [
    //     <DrawerForm
    //         trigger={
    //             <Button type="primary">
    //                 Create
    //                 </Button>
    //         }>
    //         <Form />
    //     </DrawerForm>
    // ],
}

function TestListPage() {
    return (
        <PageContainer header={header}>
            <Table />
        </PageContainer>
    )
}


export default TestListPage;