import React from 'react';

export default function Comp() {
  console.log('wilson')
  return (
    <React.Fragment>
      Register
    </React.Fragment>
  )
}
