import logs from './logs';
import CryptoJS from 'crypto-js';
import * as config from '@config/const';

export const isClient = typeof window !== undefined;

export const dev = process.env.NODE_ENV !== 'production';

/**
 * Set theme setting to local storage
 * @param  {Obj} data
 */
export function setThemeSetting(data) {
	try {
		if (isClient) {
			window.localStorage.setItem(config.store.theme, JSON.stringify(data));
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('setThemeSetting', error);
	}
}

/**
 * Get theme setting from local storage
 * @return {Obj}
 */
export function getThemeSetting() {
	try {
		if (isClient) {
			return JSON.parse(window.localStorage.getItem(config.store.theme));
		} else {
			throw new Error(`isClient: ${isClient}`);
		}
	} catch (error) {
		logs.warn('getThemeSetting', error);
	}
}

/**
 * @param  {String} data
 * @param  {String} secret
 * 
 * @return {String} 
 * encrypt data
 */

export function encrypt(data, secret) {
	return CryptoJS.AES.encrypt(JSON.stringify(data), secret)
}
/**
 * @param  {String} data
 * @param  {String} secret
 * 
 * @return {Obj}
 * decrypt data
 */

export function decrypt(data, secret) {
	return JSON.parse(CryptoJS.AES.decrypt(data, secret))
}