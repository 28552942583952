import TestListPage from "./list";
import TestAddPage from "./create";
import TestEditPage from "./edit";
import TestDetailPage from "./detail";
import TestUploadPage from "./upload";

console.log(TestDetailPage)
export const routes = [
  {
    exact: true,
    path: "/admin/leads",
    component: TestListPage,
  },
  {
    path: "/admin/leads/create",
    component: TestAddPage,
  },
  {
    path: "/admin/leads/upload",
    component: TestUploadPage,
  },
  {
    path: "/admin/leads/:id/edit",
    component: TestEditPage,
  },
  {
    path: "/admin/leads/:id",
    component: TestDetailPage,
  },
];
