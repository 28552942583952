import React from 'react';
// route
import { renderRoutes } from 'react-router-config';

export default function Comp(props) {
  const { route: { routes } } = props;
  return (
    <React.Fragment>
      {renderRoutes(routes)}
    </React.Fragment>
  )
}
