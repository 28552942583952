import React from "react";
import ReactDOM from "react-dom";
// router
import { Router } from "react-router-dom";
import { router, history } from "@router";
import { Provider } from 'react-redux';
import { store } from '@libs/store';
import { ConnectedRouter } from 'connected-react-router'

import { renderRoutes } from "react-router-config";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import "ant-design-pro/dist/ant-design-pro.css"; // 统一引入样式
import getConfig from "@utils/config";
// import { removeUserData } from '@utils/auth';


import { Spin } from "antd";
// dev-tool
import reportWebVitals from "./reportWebVitals";

function App() {
  // console.log(router);
  const { isLoading, error } = useAuth0();
  if (error) {
    return <div>Oops... {error.message}</div>;
  }
  console.log('store')
  console.log(store)

  if (isLoading) {
    return <Spin />;
  }
  return <Router history={history}>{renderRoutes(router)}</Router>;
}

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  redirectUri: window.location.origin,
  onRedirectCallback,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Auth0Provider {...providerConfig}>
          <App />
        </Auth0Provider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
