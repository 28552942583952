
export const store = {
    user: 'cur',
    token: 'jtk',
    theme: 'theme_setting',
}

export const secret = {
    user: 'gSZCXiDNQj1W7ehCZlBTKko1',
    token: 'gSZCXmNDqmxUBcWrLU_H1RC3',
    theme: 'gSZCXmNDqmxUBcWrLU_H1RC3',
}

export const theme = {
    mode: 'light',
    primaryColor: '#40a9ff',
}

export const site = {
    title: 'Antd Pro',
    fullName: 'Antd Pro template for CRA',
    logo: 'https://preview.pro.ant.design/static/logo.f0355d39.svg',
    banner: 'https://blackpearlcharters.co.nz/wp-content/uploads/2019/10/Black-Pearl-fishing-charters-Logo-1.png',
}

export const status = {
    active: 'ACTIVE',
    delete: 'DELETED',
}