import { Tooltip } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
// styles
import less from './styles.module.less'

const BlockCheckbox = ({
    value,
    onChange,
    list: propsList,
}) => {

    const list = propsList || [
        {
            key: 'sidemenu',
            title: 'Side menu',
            url: 'https://gw.alipayobjects.com/zos/antfincdn/XwFOFbLkSM/LCkqqYNmvBEbokSDscrm.svg',
        },
        {
            key: 'topmenu',
            title: 'Top menu',
            url: 'https://gw.alipayobjects.com/zos/antfincdn/URETY8%24STp/KDNDBbriJhLwuqMoxcAr.svg',
        },
    ]

    return (
        <div className={less.content} key={value}>
            {list.map((item) => (
                <Tooltip title={item.title} key={item.key}>
                    <div className={less.item} onClick={() => onChange(item.key)}>
                        <img src={item.url} alt={item.key} />
                        <div
                            className={less.selectIcon}
                            style={{
                                display: value === item.key ? 'block' : 'none',
                            }}>
                            <CheckOutlined />
                        </div>
                    </div>
                </Tooltip>
            ))}
        </div>
    )
}

export default BlockCheckbox